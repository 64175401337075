import eight from '../assets/svg/projects/eight.svg'
import seven from '../assets/svg/projects/seven.svg'
import six from '../assets/svg/projects/six.svg'
import birdino from '../assets/projects/Birdino.png'
import endup from '../assets/projects/endup.png'
import socialhall from '../assets/projects/socialhall.png'
import faynazz from '../assets/projects/faynazz.png'
import paywith from '../assets/projects/paywith.png'
import drinkchico from '../assets/projects/drinkchico.png'
import mddirect from '../assets/projects/mddirect.png'
import weedonline from '../assets/projects/weedonline.png'
import hausandhues from '../assets/projects/hausandhues.png'
import nouriehair from '../assets/projects/nouriehair.png'
import visoenergy from '../assets/projects/visoenergy.png'
import paaq from '../assets/projects/paaq.PNG'
import ivy from '../assets/projects/ivy.PNG'
import wits from '../assets/projects/wits.PNG'
import pixel from '../assets/projects/pixel.PNG'
import spordy from '../assets/projects/spordy.PNG'
import maweidi from '../assets/projects/maweidi.PNG'

export const projectsData = [
    {
        id: 1,
        projectName: 'Birdino',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://birdino.com/',
        image: birdino
    },
    {
        id: 1,
        projectName: 'End-up',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://enduptech-home.vercel.app/',
        image: endup
    },
    {
        id: 1,
        projectName: 'Social Hall',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://socialhall.vercel.app/',
        image: socialhall
    },
    {
        id: 1,
        projectName: 'Faynazz',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://mmj.vercel.app/',
        image: faynazz
    },
    {
        id: 1,
        projectName: 'Pay-with',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://www.paywith.com/',
        image: paywith
    },
    {
        id: 1,
        projectName: 'Drink-Chico',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://drinkchico.com/',
        image: drinkchico
    },
    {
        id: 1,
        projectName: 'MD-direct',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://sams-fresh-site-66e135.webflow.io/',
        image: mddirect
    },
    {
        id: 1,
        projectName: 'Weed-online',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://weedonline.io/',
        image: weedonline
    },
    {
        id: 1,
        projectName: 'Haus&Hues',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://hausandhues.com/',
        image: hausandhues
    },
    {
        id: 1,
        projectName: 'Nourie-hair',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://www.nouriehair.com/',
        image: nouriehair
    },
    {
        id: 1,
        projectName: 'Viso-energy',
        projectDesc: '',
        tags: [],
        code: '',
        demo: 'https://visoenergy.com/',
        image: visoenergy
    },
    {
        id: 1,
        projectName: 'Paaq',
        projectDesc: 'This project about soical media platform that provide direcly connection with celebrities.',
        tags: ['Next JS,', 'Node JS,', 'Firebase,', 'React-Spring,', ],
        code: '',
        demo: 'https://www.paaq.app',
        image: paaq
    },
    {
        id: 2,
        projectName: 'IVY',
        projectDesc: 'This project is a e-commerce shop, Customer can be buy product and manage them and make payment.',
        tags: ['React JS,', 'PHP-Laravel,', 'Redux-Saga'],
        code: '',
        demo: 'https://ivy-beds-blinds.netlify.app/',
        image: ivy
    },
    // {
    //     id: 3,
    //     projectName: 'Wits',
    //     projectDesc: 'This project about a TV web app where contestants face off in knowledge quizzes for a chance to win cash prizes.',
    //     tags: ['React JS,', 'PHP-Laravel,', 'Socket io'],
    //     code: '',
    //     demo: 'https://wits-tv-sufian-zulfiqar-5jp7wylx5-sufianzulfiqar786.vercel.app',
    //     image: wits
    // },
    {
        id: 4,
        projectName: 'Pixels',
        projectDesc: "This project about NFT's digital uniqueness on the blockchain, certifying ownership and authenticity.",
        tags: ['React JS,', 'Tailwind CSS'],
        code: '',
        demo: 'https://nft-web-sample.netlify.app/',
        image: pixel
    }, 
    {
        id: 5,
        projectName: 'Spordy',
        projectDesc: 'This project about sports platform, that connecting sports fans through a social sports platform.',
        tags: ['React JS,', 'Bootstrap'],
        code: '',
        demo: 'https://spordy.netlify.app/',
        image: spordy
    },
    // {
    //     id: 6,
    //     projectName: 'Maweidi',
    //     projectDesc: 'This project about health care, that helps to connect with doctor for appointments and online consultations.',
    //     tags: ['React JS,', 'PHP-Laravel,', 'Socket io'],
    //     code: '',
    //     demo: 'https://maweidi-dashboard.netlify.app/',
    //     image: maweidi
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/

import React, { useEffect, useRef } from 'react';
import { HashLink as NavLink } from 'react-router-hash-link';
import Typed from 'react-typed';
import { headerData } from '../../../data/headerData';
import './landing.css';
import logo from '../../../assets/common/images/logo.png'

import {
    FaFacebook, FaGithub, FaLinkedin, FaTwitter
} from 'react-icons/fa';
import { contactsData } from '../../../data/contactsData';

// video 
import topVideo from '../../../assets/video/topVideo.mp4'

function LandingUI({ theme, drawerOpen, classes }) {

    const videoRef = useRef(null);

    useEffect(() => {
        videoRef.current.play();
    }, []);

    return (
        <>

            <div class="parent">
                <div className='video-parent'>
                    <video src={topVideo} ref={videoRef} autoplay muted loop></video>
                </div>
                <div class="child"></div>
                <div class="child-1st d-flex justify-content-center" >
                    <div
                        className='hero-section-detail d-flex justify-content-center align-items-center flex-column'
                        style={{ color: theme.tertiary }}
                    >
                        <h1 className='text-center text-light pb-1 pb-lg-0'>WE BUILD & REFINE  ADVANCED <br /> WEB SOLUTIONS </h1>
                        <div className=' d-flex flex-column flex-lg-row align-items-center justify-content-center typing-text'>
                            <span className='pr-lg-1 text-light' style={{ lineHeight: '1em' }}>We deliver best-in-class</span>
                            <Typed
                                strings={[
                                    'web design',
                                    'web development',
                                    'marketing services',
                                ]}
                                typeSpeed={40}
                                backSpeed={50}
                                className="typed-header"
                                style={{ color: theme.primary, fontSize: '20px' }}
                                loop
                            />

                            <span className='pl-lg-1 text-light' style={{ lineHeight: '1em' }}>with Zen approach.</span>
                        </div>
                        {/* <p>{headerData.desciption}</p> */}

                        <div className='pt-4 pt-lg-3'>

                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <button className={classes.contactBtn}>
                                    Contact
                                </button>
                            </NavLink>


                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='landing' id="home" style={{ backgroundColor: theme.quaternary }}>
            <div className='landing--container'>
                <div
                    className='landing--container-left'
                    style={{ backgroundColor: theme.quaternary }}
                >
                    <div className='lcl--content'>
                        {contactsData.linkedIn && (
                            <a
                                href={contactsData.linkedIn}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaLinkedin
                                    className='landing--social linkedin-icon'
                                    style={{ color: theme.primary }}
                                    aria-label='LinkedIn'
                                />
                            </a>
                        )}
                        {contactsData.twitter && (
                            <a
                                href={contactsData.twitter}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaTwitter
                                    className='landing--social twitter-icon'
                                    style={{ color: theme.primary }}
                                    aria-label='Twitter'
                                />
                            </a>
                        )}
                        {contactsData.facebook && (
                            <a
                                href={contactsData.facebook}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FaFacebook
                                    className='landing--social facebook-icon'
                                    style={{ color: theme.primary }}
                                    aria-label='facebook'
                                />
                            </a>
                        )}
                    </div>
                </div>
                <img
                    src={logo}
                    alt=''
                    className='landing--img'
                    style={{
                        opacity: `${drawerOpen ? '0' : '1'}`,
                        borderColor: theme.primary,
                    }}
                />
                <div
                    className='landing--container-right'
                    style={{ backgroundColor: theme.secondary }}
                >
                    <div
                        className='lcr--content'
                        style={{ color: theme.tertiary }}
                    >
                        <h1>{headerData.name}</h1>
                        <Typed
                            strings={[
                                'Frontend Development',
                                'React JS',
                                'Angular JS',
                                'Vue JS',
                                'Backend Development',
                                'PHP',
                                'Laravel',
                                'Node JS',
                                'Express JS',
                                'Fullstack Development',
                                'MERN',
                                'MEAN',
                                'MEVN',
                                'PERN',
                                'DataBases',
                                'MySQL',
                                'MongoDB',
                                'PostgreSQL',
                                'Firebase',
                            ]}
                            typeSpeed={40}
                            backSpeed={50}
                            className="typed-header"
                            style={{ color: theme.primary, fontSize: '20px' }}
                            loop
                        />
                        <p>{headerData.desciption}</p>

                        <div className='lcr-buttonContainer'>
                            
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <button className={classes.contactBtn}>
                                    Contact
                                </button>
                            </NavLink>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        </>
    );
}

export default LandingUI;
import React from 'react';
import { FaCode, FaPlay } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';
import placeholder from '../../../assets/png/placeholder.png';
import './single-project.css';

function SingleProjectUI({ id, name, desc, tags, code, demo, image, theme, classes }) {


    return (
        <Fade bottom>
            <div
                key={id}
                // className='singleProject'
                style={{ backgroundColor: theme.quaternary, boxShadow: ' rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' }}
            >
                <div className='projectContent px-0 d-flex align-items-center' >
                    
                    <img src={image ? image : placeholder} alt={name} style={{objectFit:'contain', width:'100%', height:'100%'}} />
                  
                </div>
                {/* <p
                    className='project--desc1 '
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    <h2
                        id={name.replace(' ', '-').toLowerCase()}
                        style={{ color: theme.tertiary }}
                    >
                        {name}
                    </h2>
                </p>
                <p
                    className='project--desc'
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    {desc}
                </p>
                <div
                    className='project--lang'
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    {tags.map((tag, id) => (
                        <span className='' key={id}>{tag}</span>
                    ))}
                </div> */}

                
            </div>
        </Fade>
    );
}

export default SingleProjectUI;

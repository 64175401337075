import React from 'react';
import { HiArrowRight } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { projectsData } from '../../../data/projectsData';
import SingleProject from '../../container/single-project';
import './projects.css';

function ProjectsUI({ theme, classes }) {

    return (
        <>
            {projectsData.length > 0 && (
                <div className="" id="projects" style={{ backgroundColor: theme.secondary }}>
                    <div className="projects--header">
                        <h1 style={{ color: theme.primary }}>Featured work</h1>
                    </div>
                            <div className="row  mx-lg-2 project-lg-padding" >
                            {projectsData.map(project => (
                               <div className=" col-md-6 col-lg-4 mb-4 mb-md-2 pb-2 mt-md-0 d-flex justify-content-center px-2">
                                 <a href={project?.demo} target="_blank">
                                 <SingleProject
                                    theme={theme}
                                    key={project.id}
                                    id={project.id}
                                    name={project.projectName}
                                    desc={project.projectDesc}
                                    tags={project.tags}
                                    code={project.code}
                                    demo={project.demo}
                                    image={project.image}
                                />
                                 </a>
                               </div>
                            ))}
                            </div>

                        {/* {projectsData.length > 3 && (
                            <div className="projects--viewAll">
                                <Link to="/projects">
                                    <button className={classes.viewAllBtn}>
                                        View All
                                        <HiArrowRight className={classes.viewArr} />
                                    </button>
                                </Link>
                            </div>
                        )} */}
                    
                </div>
            )}

        </>
    )
}

export default ProjectsUI

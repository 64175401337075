export const contactsData = {
    email: 'info@labzsol.com',
    phone: '+92 300-0992532',
    address: 'Gulberg III, Lahore',
    github: 'https://github.com/said7388',
    facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/',
    twitter: 'https://twitter.com/',
    medium: 'https://medium.com/',
    stackOverflow: 'https://stackoverflow.com/users',
    devUsername: "LabzSol"
}